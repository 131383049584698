$base-space: 8px;

$heading-font: 'Questrial', sans-serif;
$body-font: 'DM Sans', sans-serif;

$color-primary: #ff0068;
$color-secondary: #7c39ff;

$color-navy: #1c1624;
$color-body: #65606c;

$color-cyan: #00d7e1;
$color-red: #ff0000;

@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';

@import '../node_modules/hamburgers/_sass/hamburgers/hamburgers';

@import 'animations/all';
@import 'config/all';
@import 'functions/all';
@import 'utilities/all';
@import 'mixins/all';
@import './styles';

@import 'components/all';

html,
body {
    margin: 0;
    font-family: $body-font;

    overflow-x: hidden;
    overflow-y: auto;
}

.roomstay {
    transition: background-color 0.5s;

    &.--theme {
        &-dark {
            background-color: $color-navy;
        }

        &-light {
            background-color: white;
        }

        &-primary {
            background-color: $color-secondary;
        }

        &-blog {
            background-color: white;

            .blog-content {
                @include layout();
                margin: 0 auto;
            }
        }
    }
}

.container {
    max-width: 1320px;
    z-index: 5;

    &.--small {
        max-width: 850px;
    }

    &.--padded {
        padding-top: 75px;
        padding-bottom: 75px;

        @media screen and (max-width: 768px) {
            padding-top: 60px;
            padding-bottom: 30px;
        }
    }

    &.--with-bg-ovolo {
        background: url(../public/assets/img/synxis-vs-roomstay/ovolo-icon.svg) no-repeat center top;
        background-size: 38%;
    }
}

.divider {
    width: 100%;
    height: 2px;
    background-color: #e5e5e5;
    margin: 40px 0;
}

.centered-body {
    min-height: calc(100vh - 96px);
    // border-radius: 12px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    position: relative;

    &.--no-min-height {
        min-height: unset;
    }

    padding: {
        top: 170px;
        bottom: $base-space * 8;
    }

    &.--dark {
        background-color: $color-navy;
        color: #ffffff;
    }

    &.--purple {
        background-color: $color-secondary;
        color: #ffffff;
    }

    &.--white {
        background-color: white;
        color: black;
    }

    &--joiner {
        position: absolute;
        left: 0;
        right: 0;
        top: -20px;

        transform-origin: top center;
        transform: rotate(-8deg) translateY(-250px);
        width: 100vw;

        &::before,
        &::after {
            position: absolute;
            left: -20vw;
            width: 140vw;

            top: 0;

            height: 340px;

            content: '';
        }

        &::before {
            top: 200px;
        }

        &.--to-white {
            &::before {
                background-color: $color-navy;
            }

            &::after {
                background-color: white;
            }
        }

        &.--to-navy {
            &::before {
                background-color: white;
            }

            &::after {
                background-color: $color-navy;
            }
        }
    }

    &.--curved-top {
        border-top-left-radius: 1000px 200px;
        border-top-right-radius: 1000px 200px;
        margin-top: -150px;
    }
    &.--pad-bottom--heavy {
        padding-bottom: 200px;
    }
}

.homepage-contained-image {
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen-size(m-) {
        margin-bottom: $base-space * 4;
    }

    img {
        max-width: 100%;
    }

    .bordered-image {
        @include withBorderRadius;
        overflow: hidden;
    }
}

.u-text-center {
    text-align: center;
}

.u-text-right {
    text-align: right;
}

:root {
    --color-logo: #{$color-secondary};
    --color-primary: #{$color-primary};
    --color-secondary: #{$color-secondary};
}
