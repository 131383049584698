.u-pad {
    padding: $base-space * 2;

    &--large {
        padding: $base-space * 4;
    }
}

.u-pad-right {
    padding-right: $base-space * 2;
}

.u-pad-top {
    &--none {
        padding-top: 0 !important;
    }
}
