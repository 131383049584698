@keyframes fade-in-up {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.a-fade-in-up {
    display: block;
    height: 100%;

    @media (max-width: 768px) {
        height: auto;
    }

    opacity: 0;

    &.--active {
        animation: fade-in-up forwards 0.5s ease-in-out;
    }
}
