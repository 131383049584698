@import '../../node_modules/swiper/swiper.scss';

.swiper-button-next {
    top: 100%;
    margin-top: 0 !important;

    @include screen-size(m-) {
        left: 87%;
    }
    @include screen-size(m) {
        left: 83%;
    }
}
.swiper-button-prev {
    top: 100%;
    margin-top: 0 !important;

    @include screen-size(m-) {
        left: 74%;
    }
    @include screen-size(m) {
        left: 79%;
    }
}

.swiper-button-prev:after {
    color: #fff;
    font-size: 10px;
}
.swiper-button-next:after {
    color: #fff;
    font-size: 10px;
}

.swiper-container {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: visible;
}

.swiper-element {
    background-color: white;
    box-shadow: 0px 15px 30px rgba(28, 22, 36, 0.1);
    border-radius: 30px;

    display: flex;
    align-items: center;

    &--image {
        display: flex;
        margin-bottom: 60px;

        img {
            height: 75px;

            max-width: 100%;
        }
    }

    &--link {
        text-decoration: none;
        color: $color-body;
    }
}
