@import 'fonts';
@import 'variables';
@import 'swiper/scss';
@import 'main';

html {
    scroll-behavior: smooth;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: $body-font-family;
}

* {
    box-sizing: border-box;
}

body.lock-scroll {
    height: 100%;
    overflow: hidden;
    width: 100%;
    position: fixed;
}
