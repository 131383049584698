.link {
    text-decoration: none;
    cursor: pointer;

    &.--secondary {
        color: $color-secondary;
    }
}

.nav-link {
    color: white;
    position: relative;
    height: 100%;
    white-space: nowrap;

    &:not(.--no-bottom-animation) {
        .nav-link--animation {
            position: relative;

            &::after {
                position: absolute;

                bottom: -6px;
                left: 51%;
                right: 51%;
                height: 2px;

                border-radius: 88px;

                background: linear-gradient(90deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);

                content: '';

                transition: left 0.2s, right 0.2s, background 0.5s;
            }
        }

        &:active,
        &:hover {
            .nav-link--animation {
                &::after {
                    left: 25%;
                    right: 25%;
                }
            }
        }

        &.active {
            .nav-link--animation {
                &::after {
                    left: 10%;
                    right: 10%;
                }
            }
        }

        &.dark {
            .nav-link--animation {
                &::after {
                    background: #5225a7;
                }
            }
        }
    }
}

.nav-link:not(:first-child) {
    margin-left: 24px;
    padding-left: 24px;

    position: relative;

    &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        width: 1px;

        background-color: white;
        opacity: 0.15;
        height: 21px;
        top: 50%;
        transform: translateY(-50%);

        content: '';
    }
}

.nav-menu-wrapper {
    box-shadow: 8px 8px 20px 0px rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    overflow: hidden;
    transform: translateZ(0);
}

.nav-menu {
    background-color: white;
    color: #000;
    padding: 8px 0;

    &--item {
        padding-left: 8px;
        padding-right: 8px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    .nav-link {
        color: #000;
    }

    @media only screen and (max-width: 992px) {
        background-color: transparent;

        &--item {
            &:last-child {
                margin-bottom: 32px;
            }
        }

        .nav-link {
            color: darken($color: #ffffff, $amount: 15);
        }
    }
}

.nav-arrow-icon {
    height: 16px;
    width: 32px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    border-radius: 4px;
    margin-left: 8px;
    top: 2px;

    .nav--left-bar,
    .nav--right-bar {
        width: 16px;
        height: 2px;
        position: absolute;
        background-color: transparent;
        float: right;
        border-radius: 2px;
        display: block;

        &:after {
            content: '';
            background-color: white;
            width: 10px;
            height: 2px;
            display: block;
            float: right;
            transition: all 0.3s cubic-bezier(0.25, 1.7, 0.35, 0.8);
            z-index: -1;
        }
    }

    .nav--left-bar {
        top: 7px;
        left: 2px;
        transform: rotate(35deg);
        &:after {
            border-radius: 6px 10px 10px 6px;
        }
    }

    .nav--right-bar {
        top: 10px;
        left: 10px;
        transform: rotate(-35deg);
        &:after {
            border-radius: 10px 6px 6px 10px;
        }
    }

    &.open {
        .nav--left-bar:after {
            transform-origin: center center;
            transform: rotate(-70deg);
        }
        .nav--right-bar:after {
            transform-origin: center center;
            transform: rotate(70deg);
        }
    }
}

.--theme-light {
    .header {
        .nav-link {
            color: $color-navy;
        }
    }
}

.--theme-primary {
    .nav-link {
        &--animation {
            &::after {
                background: #5225a7;
            }
        }
    }
}

[class*='article-theme'] {
    .nav-link {
        &--animation {
            &::after {
                background: #5225a7 !important;
            }
        }
    }
}
