@import 'fade-in-up';

.--a-d-200 {
    animation-delay: 200ms !important;
}

.--a-d-400 {
    animation-delay: 400ms !important;
}

.--a-d-600 {
    animation-delay: 600ms !important;
}

.--a-d-800 {
    animation-delay: 800ms !important;
}

.--a-d-1000 {
    animation-delay: 1s !important;
}
