@import '@/styles/styles';

.container {
    :global {
        .nav-link.link {
            display: block;

            @include media-breakpoint-down(lg) {
                display: initial;
            }
        }
    }
}

.guestPortal {
    align-items: center;
    background-color: #9747ff;
    border-radius: 7px;
    cursor: pointer;
    display: flex;
    gap: 10px;
    height: auto;
    height: 36px;
    justify-content: center;
    max-height: 36px;
    width: 100%;

    @include media-breakpoint-down(lg) {
        margin: 0 auto;
        padding: 10px;
        width: -moz-fit-content;
        width: fit-content;
    }

    @media screen and (max-width: 374px) {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100%;
    }

    .tag {
        align-items: center;
        background-color: #ed4362;
        border-radius: 10px;
        color: #fff;
        display: flex;
        font-family: DM Sans;
        font-size: 12px;
        font-weight: 700;
        justify-content: center;
        line-height: normal;
        padding: 0 4px;
        width: auto;
    }

    .textGuestPortal {
        background: linear-gradient(135deg, #fff 0%, #f0b310 100%);
        background-clip: text;
        -webkit-background-clip: text;
        font-family: DM Sans;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        -webkit-text-fill-color: transparent;
    }

    .textBook {
        align-items: center;
        color: #fffcfc;
        display: flex;
        font-family: Questrial;
        font-size: 12px;
        font-weight: 400;
        line-height: 110%; /* 11px */
        margin-top: 2px;
    }
}
