.roomstay .card {
    border-radius: 30px;
    transition: all 0.3s;

    display: flex;
    flex-direction: column;
    height: 100%;

    justify-content: space-between;

    margin-bottom: $base-space * 4;
    min-height: 200px;

    overflow: hidden;
    padding: $base-space * 5;

    &.--small {
        min-height: 250px;
    }

    &.--theme {
        .content {
            order: 2;
        }

        .cardIcon {
            order: 1;
        }
    }

    &.--primary {
        background-color: $color-primary;
        box-shadow: 0px 15px 30px rgba(255, 0, 104, 0.5);

        .headline,
        .body-text {
            color: #ffffff !important;

            &::after {
                background-color: $color-primary;
            }
        }

        .headline.--with-spacer::after {
            background-color: white;
        }
    }

    &.--secondary {
        background-color: $color-secondary;
        box-shadow: 0px 15px 30px rgba(124, 57, 255, 0.5);

        .headline,
        .body-text {
            color: #ffffff !important;

            &::after {
                background-color: $color-primary;
            }
        }
    }

    &.--cyan {
        background-color: $color-cyan;
        box-shadow: 0px 15px 30px rgba(0, 215, 225, 0.5);

        .headline,
        .body-text {
            color: $color-navy !important;

            &::after {
                background-color: $color-navy;
            }
        }
    }

    &.--navy {
        background-color: $color-navy;
        box-shadow: 0px 15px 30px rgba(28, 22, 36, 0.25);

        .headline,
        .body-text {
            color: #ffffff !important;

            &::after {
                background-color: $color-primary;
            }
        }
    }

    &.--white {
        background-color: #ffffff;
        box-shadow: 0px 15px 30px rgba(28, 22, 36, 0.1);

        .headline,
        .body-text {
            color: $color-navy !important;

            &::after {
                background-color: $color-primary;
            }
        }
    }

    &.--purple {
        background-color: #f1ecff;
        box-shadow: 0px 32px 64px -16px rgba(124, 57, 255, 0.2), 0px 16px 24px -8px rgba(124, 57, 255, 0.25);
        border: 3px solid var(--RoomStay-Colours-Purple, #7c39ff);
        justify-content: unset;

        .headline,
        .body-text {
            color: var(--RoomStay-Colours-Coal, #1c1624);
            font-family: DM Sans;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.54px;

            &::after {
                background-color: #7c39ff;
            }
        }

        .content {
            margin-top: 30px;

            .headline {
                color: var(--RoomStay-Colours-Coal, #1c1624);
                font-family: Questrial;
                font-size: 32px;
                font-style: normal;
                font-weight: 400;
                line-height: 40px;
                letter-spacing: -0.96px;

                @media screen and (max-width: 998px) {
                    color: var(--RoomStay-Colours-Coal, #1c1624);
                    font-family: Questrial;
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: -0.84px;
                }
            }
        }

        .cardIcon {
            align-items: center;
            border-radius: 50%;
            display: flex;
            flex-shrink: 0;
            height: 50px;
            justify-content: center;
            padding: 5px;
            width: -moz-fit-content;
            width: fit-content;
            width: 50px;
            position: relative;
            transition: all 0.3s;
            background-color: #7c39ff;

            svg {
                height: 25px;
                width: 25px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                border: 0.653061px solid red;
                border-radius: 50%;
                filter: blur(4.30612px);
                box-shadow: 0px 0px 0px 30px rgba(124, 57, 255, 0.05), 0px 0px 0px 60px rgba(124, 57, 255, 0.05), 0px 0px 0px 90px rgba(124, 57, 255, 0.05);
            }

            &::before {
                width: 117%;
                height: 117%;
                left: -10.2%;
                top: -10.2%;
                animation: flashy 1s ease-out infinite normal;
            }

            &::after {
                width: 139%;
                height: 139%;
                left: -21.2%;
                top: -21.2%;
                animation: flashy 2s ease-out infinite normal;
            }
        }
    }

    &.--mh500 {
        min-height: 510px;
    }

    &.--mh350 {
        min-height: 350px;
    }
}

@keyframes flashy {
    0% {
        opacity: 0;
        transform: scale(0.7, 0.7);
    }

    50% {
        opacity: 0.8;
        border-color: #7c39ff;
    }

    100% {
        opacity: 0;
        transform: scale(1.2, 1.2);
    }
}

.grey-box {
    background-color: #27222f;
    border-radius: 30px;
    color: #ffffff;
    padding: 20px;
}
