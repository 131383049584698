dl {
    display: grid;
    grid-template-columns: 150px auto;
    row-gap: 1em;

    dt {
        min-width: 150px;
        font-weight: bold;
    }

    dd {
        display: inline;
        margin: 0;
    }
}
