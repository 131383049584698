.wrapper {
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    gap: 30px;
    height: 100%;
    max-width: auto;
    padding: 20px 20px;
    width: 100%;

    @media screen and (max-width: 992px) {
        background-color: transparent;
        box-shadow: none;
        max-width: 100%;
        padding: 10px 10px 32px;
        width: 100%;
    }

    .content {
        display: flex;
        gap: 25px;
        height: 100%;
        margin-bottom: 21px;
        position: relative;
        width: 100%;

        @media screen and (max-width: 992px) {
            flex-direction: column;
            gap: 20px;
        }

        .wrapperContent {
            display: flex;
            margin: 0 auto;
            width: 100%;

            @media screen and (max-width: 992px) {
                display: block;
            }

            .divider {
                background: rgba(0, 0, 0, 0.05);
                height: initial;
                margin-left: 22px;
                width: 0.5px;
            }
        }
    }
}
