.body-text {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.03em;

    color: white;

    font-family: $body-font;

    transition: opacity 0.2s;

    &.--underlined,
    .--underlined {
        display: inline-block;
        font-weight: bold;
        padding: 16px 0;
        margin-bottom: 16px;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            border-radius: 10px;
            background-color: #fff;
            opacity: 0.5;
        }
    }

    &.--cyan,
    .--cyan {
        color: $color-cyan !important;
        &::after {
            background-color: $color-cyan !important;
        }
    }

    &.--purple,
    .--purple {
        color: $color-secondary !important;
        &::after {
            background-color: $color-secondary !important;
        }
    }

    &.--red,
    .--red {
        color: $color-red !important;
        &::after {
            background-color: $color-red !important;
        }
    }

    &.--small {
        font-size: 14px;
        line-height: 18px;
    }

    &.--large {
        font-size: 24px;
        line-height: 32px;
    }

    &.--heavy {
        font-size: 40px;
        line-height: 48px;
    }

    @media screen and (max-width: 768px) {
        font-size: $base-space * 2;
        line-height: 1.4em;
    }
}

@include with-theme('light') {
    .body-text {
        color: $color-body;

        strong {
            color: $color-navy;
        }
    }
}

@include with-theme('dark') {
    .body-text {
        color: #fff;
    }
}
