.text-input {
    width: 100%;

    background-color: transparent;
    outline: none;

    border: none;

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    font-size: 18px;
    line-height: 24px;
    padding: $base-space 0;

    color: white;

    transition: color 0.5s, border 0.5s;

    &::placeholder {
        transition: color 0.5s;
        color: rgba(255, 255, 255, 0.5);
    }
}

input,
textarea {
    font-family: $heading-font;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
}

textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 130px;
}

.--theme-light {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        -webkit-text-fill-color: black;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .text-input {
        &::placeholder {
            color: #8b8f9a;
        }

        color: #130d1c;
        border-bottom: 1px solid #dfe1e5;

        &:focus {
            &::placeholder {
                color: $color-secondary;
            }
            border-bottom: 1px solid $color-secondary;
        }
    }
}

.--theme-primary {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        -webkit-text-fill-color: #ffffff;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
    }

    .text-input {
        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }

        color: #ffffff;
        border-bottom: 1px solid #dfe1e5;

        &:focus {
            &::placeholder {
                color: #ffffff;
            }
            border-bottom: 1px solid #dfe1e5;
        }
    }
}
