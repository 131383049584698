.be-form-group {
    width: 100%;

    > * {
        &:not(.no-marg) {
            margin-bottom: $base-space / 2;
        }

        width: 100%;
    }

    &.s-2\/2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $base-space / 2;
    }

    &.s-3\/1 {
        display: grid;
        grid-template-columns: 3fr 1fr;
        column-gap: $base-space / 2;
    }

    &.s-1\/3 {
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: $base-space / 2;
    }

    @include screen-size('m-') {
        grid-template-columns: 1fr !important;
    }
}

.helpdesk-form {
    > * {
        &:not(.no-marg) {
            margin-bottom: $base-space / 2;
        }

        width: 100%;
    }

    &.s-2\/2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $base-space / 2;
    }

    &.s-3\/1 {
        display: grid;
        grid-template-columns: 3fr 1fr;
        column-gap: $base-space / 2;
    }

    &.s-1\/3 {
        display: grid;
        grid-template-columns: 1fr 3fr;
        column-gap: $base-space / 2;
    }

    @include screen-size('m-') {
        grid-template-columns: 1fr 1fr !important;
    }
}
