.btn {
    border: unset;

    border-radius: 83px;

    color: white;

    cursor: pointer;
    display: inline-block;
    font-family: $heading-font;
    font-size: 16px;
    line-height: 16px;
    padding: 8px 24px;

    position: relative;

    text-align: center;

    text-decoration: none;

    transition: background 0.2s, opacity 0.2s;

    &.--purple {
        background: #7c39ff;
        transition: box-shadow 0.2s, transform 0.2s;

        &:not([disabled]) {
            &:focus,
            &:hover {
                border-radius: 88px;
                box-shadow: 0px 5px 10px rgba(102, 25, 255, 0.5);
                outline: none;
                transform: translateY(-4px);
            }
        }
    }

    &.--primary {
        background: linear-gradient(90deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);

        transition: box-shadow 0.2s, transform 0.2s;

        &:not([disabled]) {
            &:focus,
            &:hover {
                box-shadow: 0px 5px 10px rgba(102, 25, 255, 0.5);
                outline: none;
                transform: translateY(-4px);
            }
        }
    }

    &.--secondary {
        background: #5225a7;
    }

    &:disabled {
        opacity: 0.5;
    }
}

.--theme-primary {
    .btn {
        &.--primary {
            background: #5225a7;
        }
    }
}
