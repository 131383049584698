.roman-list,
.lettered-list,
.numbered-list {
    counter-reset: listStyle;
    list-style: none;

    padding-left: 0;

    li {
        @extend .body-text;

        counter-increment: listStyle;
        margin-bottom: 1em;
        padding-left: $base-space * 4;

        position: relative;
        display: block;

        &::before {
            position: absolute;
            left: 0;

            color: $color-navy;
            font-weight: bold;

            content: counter(listStyle, upper-alpha) '.';
        }
    }

    & > ul {
        margin-left: $base-space * 4;
    }
}

.roman-list {
    li::before {
        content: counter(listStyle, upper-roman) '.' !important;
    }
}

.numbered-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    margin: 0;
    li {
        width: 20%;
        height: 20%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        margin-bottom: 0px;
    }
    li::before {
        content: counter(listStyle, decimal-leading-zero) ' ' !important;
        color: $color-primary;
        opacity: 0.25;
        font-size: 40px;
        font-weight: normal !important;
        top: calc(50% - 40px / 2);
        letter-spacing: -0.03em;
        text-align: center;
        left: -18px;
        width: 50px;
        height: 40px;
    }
}

@media screen and (max-width: 568px) {
    .numbered-list {
        height: auto;
        margin: 20px 10px;
        li {
            width: 50%;
            height: 50px;
        }
    }
}

.bullet-list {
    margin: 0 0 0 18px;
    padding: 0;
    li {
        margin: 10px 0;
    }
}
