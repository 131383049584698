.feature-card {
    margin-bottom: 150px;
    align-items: center;
    row-gap: 32px;

    &.--right {
        flex-direction: row-reverse;

        .headline {
            &.--medium {
                font-size: 40px;
                line-height: 40px;
            }

            // &::after {
            //     background-color: aqua;
            // }
        }

        .body-text {
        }
    }

    &.--left {
        flex-direction: row;

        .headline {
            &.--medium {
                font-size: 40px;
                line-height: 40px;
            }
        }

        .body-text {
        }
    }
    .divider {
        width: 32px;
        margin-top: 12px;
        margin-bottom: 12px;

        @include screen-size(m-) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    @include screen-size(m-) {
        margin-bottom: 40px;

        .headline {
            text-align: center !important;

            &::after {
                left: 50% !important;
                transform: translateX(-50%) !important;
            }
        }

        .body-text {
            text-align: center !important;
        }
    }
}
