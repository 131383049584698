.roomstay-logo {
    &.--default {
        transform: scale(2);

        .logo-text-color {
            fill: white;
        }

        .logo-color {
            fill: $color-secondary;
        }
    }
}
