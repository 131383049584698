.footer-book-a-demo-form {
    &-container {
        display: none;
        padding: 150px 0 100px 0;
        position: relative;
        z-index: 0;
        width: 100%;
        @media only screen and (max-width: 992px) {
            padding: 100px 0 64px 0;
        }
    }

    &--background-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        margin-left: calc(50% - 50vw);
        position: absolute;
        top: 0;
        width: 100vw;
        z-index: -1;
        overflow: hidden;

        .background {
            &--curve {
                flex-shrink: 0;
                height: auto;
                margin-left: calc(50% - 51vw);
                width: 102vw;
                z-index: -1;
                margin-bottom: -1px;
            }
            &--main {
                background-color: $color-secondary;
                flex-shrink: 0;
                height: 100%;
                width: 100%;
            }
        }
    }

    &--content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .content {
            &--headline {
                color: white;
                font-family: $title-font-family;
                font-size: 40px;
                font-weight: 400;
                letter-spacing: -0.03em;
                line-height: 44px;
                margin-bottom: 40px;
                max-width: 1070px;
                text-align: center;

                @media only screen and (max-width: 992px) {
                    font-size: 24px;
                    line-height: 32px;
                    margin-bottom: 29px;
                }

                .headline {
                    &--highlight {
                        background-color: $purplish-blue-darken;
                    }
                }
            }
        }
    }
}

.--theme-blog {
    .footer-book-a-demo-form {
        &-container {
            display: block;
        }
    }
}
