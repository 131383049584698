.Dropdown {
    &-root {
        * {
            font-size: 18px;
            line-height: 26px;
            font-family: $heading-font;
            color: var(--colorText);
        }

        position: relative;
        cursor: pointer;

        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }

    &-placeholder {
        &:not(.is-selected) {
            opacity: 0.5;
        }

        padding: $base-space 0;
    }

    &-arrow-wrapper {
        position: absolute;
        right: 12px;
        top: 50%;

        transform: translateY(-50%);
    }

    &-menu {
        position: absolute;

        background-color: black;

        top: calc(100% - 1px);
        left: 0;
        right: 0;
    }

    &-option {
        margin: 12px $base-space;
        margin-bottom: 0;

        padding-bottom: $base-space;

        border-bottom: 1px solid #2e243d;
    }
}
