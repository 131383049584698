.headline {
    font-family: $heading-font;

    font-size: 64px;
    line-height: 64px;
    letter-spacing: -0.03em;

    transition: font-size 0.2s, line-height 0.2s;

    margin: 0;

    font-weight: normal;

    color: white;

    .--primary-gradient,
    &.--primary-gradient {
        background: -webkit-linear-gradient(90deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .--with-background-gradient,
    &.--with-background-gradient,
    s {
        text-decoration: none;
        background: -webkit-linear-gradient(0deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);
        white-space: nowrap;
        border-radius: 3px;
        color: #fff;
        padding: 0 5px;
    }

    .--primary,
    &.--primary {
        color: $color-primary;
    }

    .--secondary,
    &.--secondary {
        color: $color-secondary;
    }

    .--cyan,
    &.--cyan {
        color: $color-cyan;
    }

    &.--normal {
        text-align: center;
        max-width: 600px;

        @media screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &.--centered {
        text-align: center;
    }

    @media screen and (max-width: 768px) {
        font-size: 48px;
        line-height: 48px;
    }

    &.--smaller {
        font-size: 24px;
        line-height: 32px;

        @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &.--larger {
        font-size: 64px;
        line-height: 64px;
        @media screen and (max-width: 768px) {
            font-size: 48px;
            line-height: 48px;
        }
    }

    &.--with-spacer {
        padding-bottom: 24px;
        margin-bottom: 24px;

        position: relative;

        &::after {
            position: absolute;

            bottom: 0;
            left: 0;

            width: 32px;
            height: 2px;
            border-radius: 40px;

            background-color: #e0dfe2;

            content: '';
        }
    }

    &.--spacer-right {
        &::after {
            left: unset;
            right: 0;
        }
    }

    &.--spacer-center {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
    p {
        margin-top: 0;
        margin-bottom: 0;
    }
}

h2.headline {
    font-size: 32px;
    line-height: 40px;
}

h3.headline {
    font-size: 24px;
    line-height: 32px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
    }
}

@include with-theme('light') {
    .headline {
        color: $color-navy;
    }
}

@include with-theme(('dark', 'primary')) {
    .headline {
        color: #fff;
    }

    .roomstay-booking-container {
        .headline {
            color: inherit;
        }
    }
}
