.homepage-demo-block {
    display: flex;
    justify-content: flex-end;

    &--inner {
        position: relative;
    }

    &--background {
        max-width: 630px;

        img {
            max-width: 100%;
        }
    }

    &--member-only-deal {
        max-width: 80vw;

        position: absolute;
        right: 0;
        top: 0;

        transition: transform 0.2s, max-width 0.2s;

        transform: translate(80px, -45px);

        @media screen and (max-width: 1440px) {
            transform: translate(0, -45px);
        }
    }

    &--checkout {
        position: absolute;

        right: 0;
        top: 35%;

        transition: transform 0.2s;

        transform: translate(101px, 0);

        @media screen and (max-width: 1535px) {
            transform: translate(0, 0);
        }
    }

    &--no-exit {
        position: absolute;

        left: 0;
        bottom: 0;

        transition: transform 0.2s;

        transform: translate(-100px, -50%);

        @media screen and (max-width: 768px) {
            transform: translate(0, -50%);
        }
    }

    @media screen and (max-width: 1200px) {
        margin-top: $base-space * 12;
    }
}
