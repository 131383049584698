.product-card {
    align-items: center;

    &.--left {
        .title {
            font-family: 'Questrial';
            font-size: 64px;
            font-weight: 400;
            line-height: 64px;
            position: relative;
            width: 100%;

            > p {
                margin-block-end: 0;
                margin-block-start: 0;
            }

            @include media-breakpoint-down(md) {
                font-size: 48px;
                font-weight: 400;
                line-height: 48px;
                margin-top: 20px;
            }

            @include media-breakpoint-down(sm) {
                width: 100%;
            }
        }

        .body-text {
            color: #000;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 22px;
            margin-top: 22px;
            text-align: start;
        }

        @include media-breakpoint-down(md) {
            .body-text {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: start !important;
            }
        }
    }

    &.--right {
        flex-direction: row-reverse;

        .title {
            font-family: 'Questrial';
            font-size: 64px;
            font-weight: 400;
            line-height: 64px;
            position: relative;

            > p {
                margin-block-end: 0;
                margin-block-start: 0;
            }

            @include media-breakpoint-down(md) {
                font-size: 48px;
                font-weight: 400;
                line-height: 48px;
                margin-top: 20px;
            }

            @include media-breakpoint-down(sm) {
                padding-right: 15px;
                width: 100%;
            }
        }

        .title:first-child {
            margin-top: 10px;
        }

        .body-text {
            color: #000;
            font-size: 24px;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 22px;
            margin-top: 22px;
            text-align: start;
        }

        @include media-breakpoint-down(md) {
            .body-text {
                font-size: 18px;
                font-weight: 400;
                line-height: 24px;
                text-align: start !important;
            }
        }
    }

    @include screen-size(m-) {
        .headline {
            text-align: center !important;

            &::after {
                left: 50% !important;
                transform: translateX(-50%) !important;
            }
        }

        .body-text {
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            text-align: start !important;
        }
    }

    .w-100 {
        border-radius: 15px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        margin-bottom: 18px;
        width: 100%;
    }
}
