.wrapperMenuItem {
    .title {
        color: #1e1e1e;
        font-family: DM Sans;
        font-size: 15px;
        font-weight: 700;
        line-height: normal;
    }
    .menuItemContent {
        .wrapperTitle {
            display: flex;
            margin-top: 20px;

            .title {
                background: linear-gradient(230deg, #763cef 0%, #e4336f 100%);
                background-clip: text;
                -webkit-background-clip: text;
                font-family: DM Sans;
                font-size: 14px;
                font-weight: 700;
                -webkit-text-fill-color: transparent;
            }

            .arrow {
                margin-left: 8px;
                margin-top: 2px;
                transition: transform 0.3s ease-in-out;
            }
        }

        .description {
            color: #777;
            font-family: Questrial;
            font-size: 12px;
            font-weight: 400;
            line-height: 110%; /* 11px */
            margin-top: 10px;
            max-width: 180px;
            white-space: initial;
        }
    }

    .menuItemContent:hover {
        .arrow {
            transform: translateX(3px);
        }
    }
}

@media screen and (max-width: 992px) {
    .wrapperMenuItem {
        .title {
            color: #ffffff;
            font-size: 14px;
        }

        .menuItemContent {
            align-items: center;
            display: flex;
            justify-content: center;
            text-align: center;

            .wrapperTitle {
                display: flex;
                margin-top: 20px;

                .title {
                    text-align: center;
                }

                .arrow {
                    display: none;
                }
            }

            .description {
                display: none;
                max-width: 100%;
            }
        }
    }
}
