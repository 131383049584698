.u-marg-bottom {
    margin-bottom: $base-space * 2;

    &--small {
        margin-bottom: $base-space * 8 - 4;
        @media screen and (max-width: 768px) {
            margin-bottom: $base-space * 2 + 4;
        }
    }

    &--large {
        margin-bottom: $base-space * 4;
    }

    &--largest {
        margin-bottom: $base-space * 8;
    }
}

.u-marg-left {
    margin-left: $base-space * 3;
}

.u-marg-left {
    margin-right: $base-space * 2;

    &--large {
        margin-right: $base-space * 4;
    }

    &--largest {
        margin-right: $base-space * 8;
    }
}
