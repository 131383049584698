.spinnerLoadMore {
    animation: video-spin 1s linear infinite;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #555;
    display: block;
    height: 16px;
    //margin: 18px auto 0 auto;
    text-align: center;
    width: 16px;
}

/* Safari */
@-webkit-keyframes video-spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes video-spin {
    0% {
        transform: rotate(0deg);
    }
    40% {
        transform: rotate(90deg);
    }
    70% {
        transform: rotate(240deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
