@import '@/styles/styles';

.headline {
    color: #ffffff;
    font-family: 'Questrial';
    font-size: 40px;
    font-weight: 400;
    letter-spacing: -0.03em;
    line-height: 40px;
    margin-left: auto;
    margin-right: auto;
    max-width: 630px;
    text-align: center;

    > p {
        padding: 0;
        margin: 0;
    }
}
.subHeadline {
    color: #ffffff;
    font-family: 'Questrial';
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    margin: 39px 0;
    text-align: center;

    > p {
        padding: 0;
        margin: 0;
    }

    @include media-breakpoint-down(md) {
        font-family: 'DM Sans';
        font-size: 18px;
        line-height: 24px;
        margin: 28px 0 14px;
    }
}
