.central-video-block {
    display: flex;
    justify-content: center;

    padding-top: $base-space * 8;
    padding-bottom: $base-space * 16;

    &--placeholder {
        position: relative;
        border-radius: 30px;
        box-shadow: 0px 15px 30px rgba(124, 57, 255, 0.5);
        width: 100%;

        cursor: pointer;
        z-index: 2;

        &-image {
            border-radius: 30px;
        }

        &-play {
            position: absolute;
            left: 50%;
            top: 50%;

            width: 80px;
            height: 80px;

            transform: translate(-50%, -50%);

            box-shadow: 0px 15px 30px grey;

            border-radius: 100%;
        }
    }

    &--content {
        position: relative;
        max-width: 650px;

        @include screen-size(l-) {
            max-width: 100%;
        }
    }

    &--video {
        max-width: 100%;

        position: relative;
        z-index: 4;

        & > div {
            max-width: 100% !important;
        }
    }

    &--colour-block {
        position: absolute;
        display: block;

        border-radius: 30px;

        z-index: 2;

        transition: all 0.4s;

        &.first,
        &.second {
            left: -$base-space * 4;
            transform: translateX(-100%);
        }

        &.second,
        &.fifth {
            height: 190px;
            width: 190px;
        }

        &.first {
            top: 0;

            width: 80px;
            height: 80px;

            background-color: $color-secondary;
        }

        &.second {
            top: 80px + $base-space * 4;
            background: linear-gradient(225deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);
        }

        &.third,
        &.fourth {
            bottom: -$base-space * 4;
            transform: translateY(100%);

            width: 80px;
            height: 80px;
        }

        &.third {
            right: 0;
            background-color: $color-cyan;
        }

        &.fourth {
            right: 80px + $base-space * 4;
            background-color: $color-secondary;
        }

        &.fifth {
            right: -$base-space * 4;
            bottom: -80px - $base-space * 4;

            background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);

            transform: translateX(100%);
        }

        &.sixth {
            right: -$base-space * 4;
            bottom: 110px;

            background-color: $color-secondary;

            width: 80px;
            height: 80px;

            transform: translateX(100%);
        }

        @include screen-size(xl-) {
            &.first,
            &.third,
            &.fourth,
            &.sixth {
                width: 60px;
                height: 60px;
                border-radius: 20px;
            }

            &.second,
            &.fifth {
                width: 120px + $base-space * 2;
                height: 120px + $base-space * 2;
            }

            &.third,
            &.fourth {
                bottom: -$base-space * 2;
            }

            &.first {
                top: -$base-space * 2;
                left: 120px + $base-space * 4;

                transform: translateY(-100%);
            }

            &.second {
                left: 0;
                top: -$base-space * 2;

                transform: translateY(-100%);
            }

            &.third {
                right: 140px + $base-space * 2;
            }

            &.fourth {
                right: 200px + $base-space * 4;
                background-color: $color-secondary;
            }

            &.fifth {
                right: 0;
                bottom: -$base-space * 2;

                background: linear-gradient(45deg, #7c39ff 0%, #bc1db5 48.96%, #ff0068 100%);

                transform: translateY(100%);
            }

            &.sixth {
                right: 140px + $base-space * 2;
                bottom: -60px - $base-space * 4;

                transform: translateY(100%);

                background-color: $color-primary;
            }
        }
    }

    &--colour-blocks {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

        width: 100%;
        height: 100%;
    }
}
