.header {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;

    z-index: 100;

    transition: background-color 0.5s;
    background-color: $color-navy;

    &.is-active {
        position: fixed !important;
    }

    &-content {
        height: 70px;

        display: flex;
        justify-content: space-between;

        align-items: center;

        &--logo {
            position: relative;
            z-index: 25;
        }

        &--nav {
            display: flex;
            height: 100%;

            .nav-link {
                display: flex;
                align-items: center;

                @media screen and (max-width: 992px) {
                    display: initial;
                    align-items: center;
                }
            }
        }
    }

    .logo-color {
        transition: fill 0.533s;
        fill: #7c39ff;
    }

    .logo-text-color {
        fill: white;
        transition: fill 0.5s;
    }

    .hamburger {
        display: none;
        position: relative;
        z-index: 25;

        &:hover {
            opacity: 1;
        }

        &-inner {
            &,
            &::before,
            &::after {
                background-color: white !important;
                transition-property: all;
            }
        }

        outline: none;
    }

    .background--mobile {
        display: none;
        width: 100%;
        height: 100%;
        position: absolute;
        background: #1c162450;
        z-index: 1;
        top: 0;
        left: 0;
        -webkit-backdrop-filter: blur(32px);
        backdrop-filter: blur(32px);
        opacity: 0;
        transition: all 0.3s;
    }

    @media screen and (max-width: 992px) {
        .container {
            position: relative;
            .background--mobile {
                display: block;
            }
        }
        &.is-active {
            .container {
                .background--mobile {
                    opacity: 1;
                }
            }
        }

        .hamburger {
            display: flex;
        }

        &-content--nav {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            transition: all 0.5s;

            overflow: hidden;
            background-color: $color-navy;

            display: grid;
            text-align: center;
            grid-auto-rows: fit-content(1em);
            padding-top: calc(var(--noti-height, 0) + 70px + 32px);
            padding-bottom: 32px;
            // row-gap: 50px;

            transform: translateY(-100%);

            .nav-link {
                margin-left: 0 !important;
                padding-left: 0 !important;

                &:not(:last-of-type),
                &:last-of-type {
                    margin-bottom: 30px;
                }

                &::before {
                    content: none !important;
                }
            }
        }

        .hamburger.is-active + &-content--nav {
            overflow: auto;
            transform: translateY(0);
        }
    }
}

.layout-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    min-height: 100vh;
    padding-top: var(--noti-height);
}

.--theme-primary {
    .header {
        background-color: $color-secondary;

        .logo-color {
            fill: white;
        }
    }
}

.--theme-light {
    .header {
        background-color: white;

        .hamburger {
            &-inner {
                &,
                &::before,
                &::after {
                    background-color: $color-navy !important;
                }
            }
        }

        &-content--logo {
            .logo-text-color {
                fill: $color-navy;
            }
        }

        .btn.--text {
            color: $color-navy;
        }
    }
    @media screen and (max-width: 992px) {
        .header {
            .is-active {
                &.hamburger {
                    .hamburger {
                        &-inner {
                            &,
                            &::before,
                            &::after {
                                background-color: white !important;
                            }
                        }
                    }
                }
            }

            &-content--logo {
                &.is-active {
                    .logo-text-color {
                        fill: white;
                    }
                }
            }

            .nav-link {
                color: white !important;
            }
        }
    }
}

.--theme-light {
    header.header {
        .nav-link:not(:first-child)::before {
            background-color: black;
        }
    }
}

.--theme-blog {
    .header {
        background-color: transparent;
        position: absolute;
    }
}

.--article-theme-dark {
    .header {
        .hamburger:not(.is-active) .hamburger-inner {
            background-color: black !important;

            &::before,
            &::after {
                background-color: black !important;
            }
        }
        .logo-text-color {
            fill: black;
        }

        .header-content--logo.is-active {
            .logo-text-color {
                fill: white;
            }
        }
    }
}

@media screen and (min-width: 992px) {
    .--article-theme-dark {
        header.header {
            .nav-link {
                color: black;
            }

            .nav-link:not(:first-child)::before {
                background-color: black;
            }
        }
    }
}
