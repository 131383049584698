@import '@/styles/styles';

.btn {
    align-items: center;
    background: $text-color-primary-dark linear-gradient(90deg, $secondary 0%, $barney 48.96%, $primary 100%);
    border: none;
    border-radius: 100px;
    box-shadow: 0px 5px 10px rgba($color: $purplish-blue, $alpha: 0.5);
    color: $white;
    cursor: pointer;
    display: inline-flex;
    font-family: $title-font-family;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    line-height: 20px;
    padding: 6px 25px;
    transition: background-color 0.3s ease, box-shadow 0.2s, transform 0.2s, opacity 0.2s;

    &.shadowNone {
        box-shadow: none;
    }

    &:disabled {
        cursor: inherit;
        opacity: 0.8;
    }

    &-secondary {
        background: $mirage;
        box-shadow: 0px 5px 10px rgba($color: $mirage, $alpha: 0.8);
    }

    &-large {
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        padding: 12px 32px;
    }

    &:hover {
        background-color: lighten($text-color-primary-dark, 10%);
        transform: translateY(-4px);
    }

    &-theme {
        &-primary {
            &.btn-secondary {
                background: #5225a7;
                box-shadow: 0px 5px 10px rgba($color: #5225a7, $alpha: 1);
            }
        }

        &-blackCurrant  {
            background: #1c1624;
            box-shadow: unset;
        }
    }
}
