footer {
    align-items: center;
    color: transparent;

    display: flex;

    margin-top: auto;
    padding: 31px 0 27px 0;

    transition: background-color 0.2s;

    .logo-color {
        fill: #d2d0d3;
    }

    .logo-text-color {
        fill: white;
    }

    .footer {
        &-content {
            display: flex;
            justify-content: flex-start;

            &--logo {
                margin-right: $base-space * 8;
            }

            &--nav {
                align-items: flex-start;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                flex-direction: column;

                &-policy {
                    margin-top: 10px;
                }

                &-links {
                    display: flex;
                    flex-wrap: wrap;
                    padding-bottom: 16px;
                }

                &-policy {
                    color: white;
                    font-size: 14px;
                    opacity: 0.5;
                }
            }
        }
    }

    @media screen and (min-width: 769px) and (max-width: 1100px) {
        .footer {
            &-content {
                &--logo {
                    margin-right: 32px;
                }

                &--nav {
                    flex-direction: column;
                    align-items: flex-start;

                    &-links {
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        row-gap: 16px;

                        .link.nav-link {
                            &:nth-child(5n + 1) {
                                margin-left: 0;
                                padding-left: 0;

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        padding: 31px 0 24px 0;
        .footer {
            &-content {
                &--logo {
                    margin-bottom: 6px;
                    margin-right: 32px;
                }

                &--nav {
                    &-links {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        row-gap: 16px;

                        .link.nav-link {
                            &:nth-child(2n + 1) {
                                margin-left: 0;
                                padding-left: 0;

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }

                    &-links:nth-child(2) {
                        .link.nav-link {
                            &:nth-child(2n) {
                                margin-left: 0;
                                padding-left: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 630px) {
        height: unset;

        .footer {
            &-content {
                flex-wrap: wrap;
                justify-content: center;

                &--logo {
                    margin-right: 0;
                }

                &--nav {
                    justify-content: center;
                    align-items: center;

                    &-links {
                        display: none;
                        margin-bottom: 16px;
                    }
                    &-policy {
                        text-align: center;
                    }
                }
            }
        }
    }
}

.--theme-light {
    footer {
        background-color: $color-navy;
    }
}

.--theme-blog {
    footer {
        background-color: $color-secondary;
    }
}
