.info-block {
    padding: $base-space * 2;
    color: white;

    font-family: $heading-font;
    border-radius: 15px;
    font-size: 18px;
    line-height: 24px;

    transition: padding 0.2s, font-size 0.2s;

    &.--purple {
        background-color: $color-secondary;
    }

    &.--pink {
        background-color: $color-primary;
    }

    @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;

        padding: $base-space;
        border-radius: 7px;
    }
}
